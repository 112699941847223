import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useEffect, useContext } from "react";
import Refresh from "../images/svg/Refresh.svg";
import Minimize from "../images/svg/Minimize.svg";
import Close from "../images/svg/Close.svg";
import getCWACookies from "../util/getCWACookies";
import MaximizeIcon from "../images/svg/Maximize_icon.svg";
import ChatModal from "./Modal";
import ChatbotLogo from "../images/svgLatest/ChatbotLogo.svg";
import ChatbotLogo_HBT from "../images/svgLatest/ChatbotLogo_HBT.svg";
import ChatbotLogo_NewHBT from "../images/svgLatest/ChatbotLogo_NewHBT.svg";
import HeaderLogo from "../images/svgLatest/HeaderLogo.svg";
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";
import MenuIcon from "../images/svgLatest/MenuIcon.svg";
import launcherWithText from "../images/svgLatest/LauncherWithText.svg";
import "../css/ripple-animation.css";
import closeBlue from "../images/svgLatest/Close_blue.svg";
import { AppContext } from "../util/appContext";
import { hooks } from "botframework-webchat-component";
import { Components } from "botframework-webchat-component";
import StaticLabels from "../util/staticLabels.json";
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
import salesPagesMessages from "../util/salesPagesMessages.json";
import { removeDLCookies } from '../util/removeDLCookies';
import { setCookie, getCookie, eraseCookie } from '../util/cookieActions';
import setCWACookies from '../util/setCWACookies';
import { func } from "prop-types";
const momenttimezone = require('moment-timezone');

var classNames = require("classnames");

const Header = ({
  isMaximised,
  handleMaximizeChange,
  handleModalChange,
  isOpenModal,
  handleSurveyCardChange,
  userLanguage,
  headerIconsDisplay,
  surveyLink,
  store,
  directLine,
  handleRefreshModalPopup,
  handleCloseModalPopup,
  isSurveyOpen
}) => {
  var headerClass = classNames({
    headerMax: isMaximised,
    headerMin: isMaximised,
    header: true
  });

  var agentBadge = classNames({
    agentBadge: true,
    displayNone: !isMaximised
  });

  const [currentPageTitle, setCurrentPageTitle] = useState("")
  const [currentPageLanguage, setCurrentPageLanguage] = useState("")
  const [currentCountrySelectedOnPortal, setCurrentCountrySelectedOnPortal] = useState("")

  const context = useContext(AppContext);

  const HBTPages = ["login", "SecureCustomerPortalRegisterPage", "searchEmpty", "searchGrid", "productDetails", "multiStepCheckoutSummaryPage", "orders", "cartPage", "quickCart",
                  "importCSVSavedCartPage", "quoteEditPage", "my-quotes", "quote-detail", "address-book", "rmarequestpage", "customerDashboardPage", "homepage", "myProfile",
                  "custServiceRequest", "techKnowledgeCenter", "techSupportRequest", "techPubs", "productDownloads"];

  const handleMenuClick = () => {
    context.setMenuOpen(!context.menuOpen);
  }

  const closeIconClick = () => {
    if (store != null) {
      store.dispatch({ //Send CloseBot event
        type: "WEB_CHAT/SEND_EVENT",
        payload: {
          name: "closeWithFeedback",
          value: getCWACookies("cwaSSOToken")
        }
      });
    }
  }


  const handleMenuItemClick = (selectedMenuItem) => {
    context.setMenuOpen(false);
    if (store != null && getCookie(window.config.businessUnit + '-EnableSendBox')) {
      store.dispatch({
        type: "WEB_CHAT/SEND_EVENT",
        payload: {
          name: "enableSendBoxEventReceived",
        }
      });
    }
    context.setDisableSendBox(true);
    store.dispatch({
      type: "WEB_CHAT/SEND_MESSAGE",
      payload: {
        text: selectedMenuItem
      }
    });
  }

  // live agent ENGLISH (EN) availability
  const checkLiveAgentAvailability = () => {
    let currenttime = new Date();
    const opentime1 = '08:00:00';
    const opentime2 = '00:00:00';
    const closetime = '03:30:00';
    let newtimezonedate = momenttimezone.utc(currenttime, null).tz('Asia/Kolkata');
    let currentday = newtimezonedate.format('d');
    let presentTime = newtimezonedate.format('HH:mm:ss');
    switch (currentday) {
      // Sunday
      case '0':
        return false;
      //Saturday
      case '6':
        if (presentTime > opentime2 && presentTime < closetime) {
          context.setCheckLiveAgentAvailable(true);
          return true;
        }
        return false;
      //Monday
      case '1':
        if (!(presentTime > opentime2 && presentTime < opentime1)) {
          context.setCheckLiveAgentAvailable(true);
          return true;
        }
        return false;
      case '2':
      case '3':
      case '4':
      case '5':
        if (!(presentTime > closetime && presentTime < opentime1)) {
          context.setCheckLiveAgentAvailable(true);
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  const checkLiveAgentAvailabilityForOtherLanguages = (
    closeTime = '16:00:00'
  ) => {
    let currenttime = new Date();
    const opentime = '08:00:00';
    const closetime = closeTime;
    let newtimezonedate = momenttimezone.utc(currenttime, null).tz('Europe/Berlin');
    let currentday = newtimezonedate.format('d');
    let presentTime = newtimezonedate.format('HH:mm:ss');
    switch (currentday) {
      // Sunday
      case '0':
        return false;
      //Saturday
      case '6':
        return false;
      //Monday,Tuesday,Wednesday,Thursday and Friday
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
        if (presentTime > opentime && presentTime < closetime) {
          context.setCheckLiveAgentAvailable(true);
          return true;
        }
        return false;
      default:
        return false;
    }
  }


  useEffect(() => {
    let liveAgentAvailable;
    context.setShowHeaderFooter(false);
    context.setShowSendBox(false);
    context.setEnableScroll(false);
    context.setHideStickyMenu(true);
    if (window.config.businessUnit === "HBT-GCEGBT" && HBTPages.includes(window.config.pageTitle)) {
      // ENGLISH
      if (window.config.preLoginSalesPages.includes(window.config.pageTitle) && window.config.language === 'en') {
        liveAgentAvailable = checkLiveAgentAvailability();
      }
      // GERMAN
      else if (window.config.preLoginSalesPages.includes(window.config.pageTitle) && window.config.language === 'de') {
        liveAgentAvailable = checkLiveAgentAvailabilityForOtherLanguages('16:00:00');
      }
      // FRENCH
      else if (window.config.preLoginSalesPages.includes(window.config.pageTitle) && window.config.language === 'fr') {
        liveAgentAvailable = checkLiveAgentAvailabilityForOtherLanguages('17:00:00');
      }
      // DUTCH
      else if (window.config.preLoginSalesPages.includes(window.config.pageTitle) && window.config.language === 'nl') {
        liveAgentAvailable = checkLiveAgentAvailabilityForOtherLanguages('17:00:00');
      }
      // ITALIAN
      else if (window.config.preLoginSalesPages.includes(window.config.pageTitle) && window.config.language === 'it') {
        liveAgentAvailable = checkLiveAgentAvailabilityForOtherLanguages('17:00:00');
      }
      // SPANISH
      else if (window.config.preLoginSalesPages.includes(window.config.pageTitle) && window.config.language === 'es') {
        liveAgentAvailable = checkLiveAgentAvailabilityForOtherLanguages('17:00:00');
      }
      else {
        context.setCheckLiveAgentAvailable(true);
      }
    }

    // if(window.config.businessUnit === "HBT-GCEGBT"  && ['login'].includes(window.config.pageTitle)) {
    //   const timeout = setTimeout(() => {
    //     if(!isMaximised && getWithExpiry(window.config.pageTitle) === null && liveAgentAvailable) {
    //       ChatWithLiveAgentBotClick();
    //     }
    //    },20000);
    //    return () => clearTimeout(timeout);
    // }
  }, []);


  // useEffect(() => {
  //   setTimeout(() => {
  //     if (window.config.businessUnit === "HBT-GCEGBT") {
  //      // console.log('Page Change Detected', currentPageTitle);

  //       window.parent.postMessage({ key: 'page_title', origin: window.location.origin }, window.config.pageOrigin);
  //       window.parent.postMessage({ key: 'page_language', origin: window.location.origin }, window.config.pageOrigin);
  //       window.parent.postMessage({ key: 'page_country', origin: window.location.origin }, window.config.pageOrigin);

  //       window.onmessage = (e) => {
  //         //console.log('page_title Event', e.data)
  //         let data
  //         switch (e.data.key) {
  //           case 'page_title':
  //             data = JSON.parse(JSON.stringify(e.data));
  //             window.config.pageTitle = data.pageTitle;
  //             setCurrentPageTitle(data.pageTitle)
  //             break;
  //           case 'page_language':
  //             data = JSON.parse(JSON.stringify(e.data));
  //             //console.log('Triggerred Page Language Change', data);
  //             window.config.language = data.pageLanguage;
  //             setCurrentPageLanguage(data.pageLanguage);
  //             break;
  //           case 'page_country':
  //             data = JSON.parse(JSON.stringify(e.data));
  //             console.log('Triggerred Page Language Change', data);
  //             window.config.countrySelectedOnPortal = data.countrySelectedOnPortal;
  //             setCurrentCountrySelectedOnPortal(data.countrySelectedOnPortal);
  //             break;
  //           case 'page_country':
  //             data = JSON.parse(JSON.stringify(e.data));
  //            // console.log('Triggerred Page Country Change', data);
  //             window.config.countrySelectedOnPortal = data.countrySelectedOnPortal;
  //             setCurrentCountrySelectedOnPortal(data.countrySelectedOnPortal);
  //             break;
  //           default:
  //             break;
  //         }
  //       };
  //     }
  //   }, 1500)
  // }, [currentPageTitle, currentPageLanguage, currentCountrySelectedOnPortal])

  const updateContextVariablesData  = () => {
    if (getCookie(window.config.businessUnit + '-Token') &&
      getCookie(window.config.businessUnit + '-Conversation') &&
      getCookie(window.config.businessUnit + '-AuthToken') &&
      getCWACookies("cwaSSOToken") &&
      getCookie(window.config.businessUnit + '-AuthToken').length === getCWACookies("cwaSSOToken").length
    ) {
      if (window.config.businessUnit === 'HBT-GCEGBT' || window.config.businessUnit === 'SPS-ECOMM') {
        if (getCookie(window.config.businessUnit + '-Language') &&
          getCookie(window.config.businessUnit + '-Language') === window.config.language) {
          context.setShowHeaderFooter(true);
          context.setShowSendBox(true);
          context.setEnableScroll(true);
          context.setHideStickyMenu(false);
        }
      }
      else {
        context.setShowHeaderFooter(true);
        context.setShowSendBox(true);
        context.setEnableScroll(true);
        context.setHideStickyMenu(false);
      }

    }
  }
  const updateContextVariables=()=>{
    if(window.config.isNewPrivacy==true){
      if(getCookie(window.config.businessUnit + '-isPrivacyAccepted')){
      updateContextVariablesData();
      }
    }
    else{
      updateContextVariablesData();
    }
  }



  const botIconClick = async () => {
    // console.log("Bot icon clicked:", Date.now()) 
    if (window.config.businessUnit === "HBT-GCEGBT") {
      handleMouseLeave();
    }
    if (window.config.businessUnit === "HBT-GCEGBT" && window.config.pageTitle == "login") {
      window.config.isNewSSO = false
    }
    if (getCookie(window.config.businessUnit + '-isPrivacyAccepted') && window.config.isNewPrivacy == true) {
      handleMaximizeChange();
      updateContextVariables();
    }
    else {
      handleMaximizeChange();
    }
  }

  const handleMouseOver = (event) => {
    // if(event && event.stopPropagation){
    // event.stopPropagation();
    // }
    // setTimeout(()=>{
    //   if(document.getElementById("logo")){
      document.getElementById("logo").style.display = 'none';
      document.getElementById("logoNew").style.display = 'block';
     window.parent.postMessage({ key: 'show_hoverLogo' }, window.config.pageOrigin);
  }

  const handleMouseLeave = () => {
   document.getElementById("logo").style.display = 'block';
    document.getElementById("logoNew").style.display = 'none';
    window.parent.postMessage({ key: 'close_launcher' }, window.config.pageOrigin);
  }

 

  // const authenticationStatus = () => {
  //       window.parent.postMessage({ key: 'auth_status', origin: window.location.origin }, window.config.pageOrigin);
  //       window.onmessage = (e) => {
  //         const data = JSON.parse(JSON.stringify(e.data));
  //         window.config.authenticationStatus = data.authenticationStatus;
  //         if(window.config.authenticationStatus === "Anonymous") {
  //           setCWACookies("cwaSSOToken", "Anonymous", 100000);
  //         }
  //         else {
  //           setCWACookies("cwaSSOToken", window.config.accessToken, window.config.tokenExpireTime);
  //         }
  //         handleMaximizeChange();
  //         context.setLauncherIcon(false);
  //         updateContextVariables();
  //       }
  // }

  // const JWTTokenUpdate = () => {
  //   let token = window.config.jwtTokenName;
  //   if(window.config.businessUnit === "SPS-ECOMM" || window.config.isChina) {
  //     token = window.config.jwtTokenNameHybris;
  //   }

  //   window.config.b2bunit = null;

  //   if(window.config.businessUnit === "HBT-GCEGBT" && window.config.pageTitle !== "login" && window.config.pageTitle !== "SecureCustomerPortalRegisterPage") {
  //     window.parent.postMessage({ key: 'b2b_unit', origin: window.location.origin }, window.config.pageOrigin);
  //   }

  //   // if(window.config.businessUnit === "HBT-GCEGBT") {
  //   //  // console.log('Triggering Page Lang & Title For HBT')
  //   //   window.parent.postMessage({ key: 'page_title', origin: window.location.origin }, window.config.pageOrigin);
  //   //   window.parent.postMessage({ key: 'page_language', origin: window.location.origin }, window.config.pageOrigin);
  //   //   window.parent.postMessage({ key: 'page_country', origin: window.location.origin }, window.config.pageOrigin);
  //   // }

  // //  window.parent.postMessage({ key: 'jwt_token', value: token, origin: window.location.origin }, window.config.pageOrigin);
    
  //   window.onmessage = (e) => {
  //     let data
  //     switch (e.data.key) {
  //       case 'jwt_token':
  //         // data = JSON.parse(JSON.stringify(e.data));
  //         // window.config.jwtToken = data.jwtToken;
  //         // if (window.config.jwtToken) {
  //         //   setCWACookies("cwaSSOToken", window.config.jwtToken, 100000);
  //         // }
        
  //         break;
  //       case 'b2b_unit':
  //         data = JSON.parse(JSON.stringify(e.data));
  //         window.config.b2bunit = data.b2bunit ? data.b2bunit : null;
  //         break;
  //       // case 'page_title':
  //       //   data = JSON.parse(JSON.stringify(e.data));
  //       //   window.config.pageTitle = data.pageTitle;
  //       //   setCurrentPageTitle(data.pageTitle)
  //       //   break;
  //       // case 'page_language':
  //       //   data = JSON.parse(JSON.stringify(e.data));
  //       //  // console.log('Triggerred Page Language Change', data);
  //       //   window.config.language = data.pageLanguage;
  //       //   setCurrentPageLanguage(data.pageLanguage)
  //       //   break;
  //       // case 'page_country':
  //       //   data = JSON.parse(JSON.stringify(e.data));
  //       //  // console.log('Triggerred Page Country Change', data);
  //       //   window.config.countrySelectedOnPortal = data.countrySelectedOnPortal;
  //       //   setCurrentCountrySelectedOnPortal(data.countrySelectedOnPortal);
  //       //   break;
  //       default:
  //         break;
  //     }
  //   };
  // }

  const minimizeBot = () => {
    document.getElementById('root').classList.remove('root-min');
    window.parent.postMessage({ key: 'minimise_bot', origin: window.location.origin }, window.config.pageOrigin);
    context.setMinimizeBot(true);
    handleMaximizeChange();
    setTimeout(() => {
      if (document.getElementById('logo')) {
        document.getElementById('logo').style.display = "block";
      }
    }, 1000)
  };

  const pageRenderSwitch = (pageTitleValue) => {
    const salesPages = (window.config.language in salesPagesMessages) ? salesPagesMessages[window.config.language] : {};
    return (pageTitleValue in salesPages) ? salesPages[pageTitleValue] : '';
  }

  
  var launcherIcon = classNames({
    launcherIcon: true,
    notificationAnimation: window.config.businessUnit === "HCE-MARKETING"
  });

  var launcherIconHoneywellAssit = classNames({
    launcherIconHoneywellAssit: true,
    botIconAnimation: window.config.businessUnit === "HCE-MARKETING"
  });


  const renderHBTGCEGBT = () => {
    if(window.config.language === 'en' || window.config.language === 'de' || window.config.language === 'fr' || window.config.language === 'it' || window.config.language === 'es' || window.config.language === 'nl') {
      return (<div>
        <div className="logoOverflow">
          <img className="minimizedLauncherIcon_HBT"
            alt="miniAvatar"
            id="logo"
            src={ChatbotLogo_HBT}
            onMouseOver={handleMouseOver}
            onClick={botIconClick} />
          <img className="minimizedLauncherIcon_NewHBT"
            alt="miniAvatar"
            id="logoNew"
            src={ChatbotLogo_NewHBT}
            onMouseLeave={handleMouseLeave}
            onClick={botIconClick} />
        </div>
      </div>);
    }
    else if(window.config.language === 'zh' && window.config.isChina){
      return (<div>
        <div className="logoOverflow">
          <img className="minimizedLauncherIcon_HBT"
            alt="miniAvatar"
            id="logo"
            src={ChatbotLogo_HBT}
            onMouseOver={handleMouseOver}
            onClick={botIconClick} />
          <img className="minimizedLauncherIcon_NewHBT"
            alt="miniAvatar"
            id="logoNew"
            src={ChatbotLogo_NewHBT}
            onMouseLeave={handleMouseLeave}
            onClick={botIconClick} />
        </div>
      </div>);
    }
  }

  const renderHBTGCEGBT_HEADER = () => {
    return (
      <div className={headerClass}>
        <div className="ask-ellie">
          <img
            className="launcherIconAvatar"
            alt="miniAvatar"
            src={HeaderLogo_HBT}
          />
          <h4 style={{ fontWeight: '600' }}>{window.config.businessUnit === "HCE-TRIDIUM" ? 'Tridium Assist' : 'Honeywell Assist'}</h4>
        </div>
        {headerIconsDisplay ? (
          <ul>
            <li>
              <a href="#" onClick={minimizeBot}>
                <img
                  alt="Minimize"
                  title="Minimize Bot"
                  src={Minimize}
                ></img>
              </a>
            </li>

            <li>
              <a
                href="#"
                onClick={context.showSurvey ? handleModalChange({
                  isOpenModal: false,
                  value: null,
                  target: "closeWithSurvey"
                }) : handleModalChange({
                  isOpenModal: true,
                  value: null,
                  target: "close"
                })}
              >
                <img alt="Close" title="Close Bot" src={Close}></img>
              </a>
            </li>
          </ul>
        ) : surveyLink === "" ? "" :
          (
            <ul>
              <li>
                <a
                  href="#"
                  onClick={handleModalChange({
                    isOpenModal: true,
                    value: null,
                    target: "shutdown"
                  })}
                >
                  <img alt="Close" title="Close Bot" src={Close}></img>
                </a>
              </li>
            </ul>
          )}
      </div>
    )
  }

  return (
    <div>
      {isMaximised ? (
        context.showHeaderFooter &&
        (window.config.isNewUI ?
          renderHBTGCEGBT_HEADER()
          :
          <div className={headerClass}>
            <div className="ask-ellie">
              <img
                alt="MenuIcon"
                src={MenuIcon}
                onClick={handleMenuClick}
                style={{ cursor: 'pointer', display: context.hideStickyMenu ? 'none' : 'block' }}
              />
              <div className="vertical" style={{ display: context.hideStickyMenu ? 'none' : 'block' }}></div>
              {window.config.businessUnit === "HBT-GCEGBT" ?
                <img
                  className="launcherIconAvatar"
                  alt="miniAvatar"
                  src={HeaderLogo_HBT}
                /> :
                <img
                  className="launcherIconAvatar"
                  alt="miniAvatar"
                  src={HeaderLogo}
                />}

              {window.config.businessUnit === "HCE-TRIDIUM" ? <h4>Tridium Assist</h4> : <h4>Honeywell Assist</h4>}
            </div>
            {headerIconsDisplay ? (
              <ul>
                {window.config.businessUnit === "SPS-PPR" || window.config.businessUnit === "SPS-ECOMM" ?
                  <li>
                    <a
                      href={window.config.helpVideoLink}
                      target="_blank"
                      className="helpVideoLink"
                      title="DCA help page 'How to'"
                    >
                      ?
                    </a>
                  </li> : ""
                }
                <li>
                  <a href="#" onClick={minimizeBot}>
                    <img
                      alt="Minimize"
                      title="Minimize Bot"
                      src={Minimize}
                    ></img>
                  </a>
                </li>
                {!context.hideStickyMenu ?
                  <li>
                    <a
                      href="#"
                      onClick={handleModalChange({
                        isOpenModal: true,
                        value: null,
                        target: "refresh"
                      })}
                    >
                      <img alt="Refresh" title="Refresh Bot" src={Refresh}></img>
                    </a>
                  </li> : ""
                }
                <li>
                  <a
                    href="#"
                    onClick={window.config.businessUnit === "HCE-TRIDIUM" ? closeIconClick : context.showSurvey ? handleModalChange({
                      isOpenModal: false,
                      value: null,
                      target: "closeWithSurvey"
                    }) : handleModalChange({
                      isOpenModal: true,
                      value: null,
                      target: "close"
                    })}
                  >
                    <img alt="Close" title="Close Bot" src={Close}></img>
                  </a>
                </li>
              </ul>
            ) : surveyLink === "" ? "" :
              (
                <ul>
                  <li>
                    <a
                      href="#"
                      onClick={handleModalChange({
                        isOpenModal: true,
                        value: null,
                        target: "shutdown"
                      })}
                    >
                      <img alt="Close" title="Close Bot" src={Close}></img>
                    </a>
                  </li>
                </ul>
              )}
          </div>)
      ) : (window.config.businessUnit === "HBT-GCEGBT" ? renderHBTGCEGBT() :
        <div><div className="logoOverflow">
        <img className="minimizedLauncherIcon_HBT"
          alt="miniAvatar"
          id="logo"
          src={ChatbotLogo_HBT}
          onMouseOver={handleMouseOver}
          onClick={botIconClick} />
        <img className="minimizedLauncherIcon_NewHBT"
          alt="miniAvatar"
          id="logoNew"
          src={ChatbotLogo_NewHBT}
          onMouseLeave={handleMouseLeave}
          onClick={botIconClick} />
      </div>
        </div>
      )}
      {document.getElementById("main") === null ? (
        ""
      ) : (
        !!directLine ?
          <Components.Composer
            directLine={directLine}
            store={store}

          >
            <ChatModal
              isOpenModal={isOpenModal}
              handleModalChange={handleModalChange}
              userLanguage={userLanguage}
              handleRefreshModalPopup={handleRefreshModalPopup}
              handleCloseModalPopup={handleCloseModalPopup}
              handleMaximizeChange={handleMaximizeChange}
            />
          </Components.Composer> : ""
      )}
      {(context.menuOpen && !window.config.isNewUI) ?
        <div className="menuContainer">
          {window.config.businessUnit === 'SPS-GDM' || window.config.businessUnit === 'SPS-PSS' ?
            context.menuItems.map((menuItem, index) => (
              <p key={index} className="menuItem" onClick={() => handleMenuItemClick(menuItem.buttonText)} >{menuItem.buttonText} </p>))
            :
            context.menuItems.map((menuItem, index) => (
              <p key={index} className="menuItem" onClick={() => handleMenuItemClick(menuItem)} >{menuItem} </p>))
          }
        </div>
        : ""
      }
      {
        context.isLiveAgent && window.config.isNewUI && !isSurveyOpen && <div className={agentBadge}>
          <div style={{ fontWeight: '500', margin: '5px 0px' }}>
            <div className="displayFlex"><span className="noMargin">{
            window.config.language ?
              liveAgentStaticLabels[window.config.language]?.liveagentConnected :
              liveAgentStaticLabels?.en?.liveagentConnected
          } &nbsp;</span><h4 className="agentNameStyle">{context.liveAgentName}</h4></div>
            {context.transcriptTranslationRequired ? <div className="displayFlex"><span className="translatedAIStyle">{
            window.config.language ?
              liveAgentStaticLabels[window.config.language]?.translatedBy :
              liveAgentStaticLabels?.en?.translatedBy
          } &nbsp;</span></div>:null}
          </div>
          {/* <p style={{ fontWeight: '500', margin: '5px 0px' }}>{
            window.config.language ?
              liveAgentStaticLabels[window.config.language]?.liveagentConnected :
              liveAgentStaticLabels?.en?.liveagentConnected
          }</p>  */}
        </div>
      }
    </div>
  );
};

export default Header;