import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useState, useContext, useCallback,useEffect } from 'react';
import ChatbotLogo from "../images/svgLatest/ChatbotLogo.svg";
import ChatbotLogo_HBT from "../images/svgLatest/ChatbotLogo_HBT.svg";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import Spinner from "./Spinner";
import Close from "../images/svg/Close.svg";
import Markdown from "markdown-to-jsx";
import StaticLabels from "../util/staticLabels.json";
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
import { setCookie, getCookie, eraseCookie } from '../util/cookieActions';
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";
import WelcomeCard from "./WelcomeCard";
import WelcomeCardHBT from "./WelcomeCardHBT";
import setCWACookies from '../util/setCWACookies';
import getCWACookies from "../util/getCWACookies";
const { useSendPostBack, useSendMessage, useSendEvent } = hooks;

var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "12px", textDecoration: 'underline' }} {...props}>
      {children}
    </a>
  );
};





const PrivacyPolicy = ({handleModalChange,handleMaximizeChange,handleCloseModalPopup,isMaximised, setDirectLineTokenCall}) => {
  const [privacyPolicyAccepted, setPrivacyAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(getCookie(window.config.businessUnit + '-isPrivacyAccepted')?true:false);
  const context = useContext(AppContext);
  const [currentPageTitle, setCurrentPageTitle] = useState("")
  const [currentPageLanguage, setCurrentPageLanguage] = useState("")
  const [currentCountrySelectedOnPortal, setCurrentCountrySelectedOnPortal] = useState(context.countrySelectedOnPortal?context.countrySelectedOnPortal:"US");
  let  contentPrivacy="";
  let languageSelected = window.config.language ? window.config.language : "en";
  if (window.config.businessUnit === 'SPS-ECOMM') {
    if (languageSelected === "en_GB") {
      languageSelected = "en";
    } else if (languageSelected === "fr_CA") {
      languageSelected = "fr";
    } else if (languageSelected === "es_CO") {
      languageSelected = "es";
    }
  }
  if (String(currentCountrySelectedOnPortal).toLowerCase() == "us" && !window.config.isChina) {
    if (languageSelected) {
      contentPrivacy = StaticLabels[languageSelected].consentPrivacyUS;
    }
    else {
      contentPrivacy = StaticLabels["en"].consentPrivacyUS;
    }
  }
  else if(window.config.isChina){
    if (languageSelected) {
      contentPrivacy = StaticLabels[languageSelected].consentPrivacyChina;
    }
    else {
      contentPrivacy = StaticLabels["en"].consentPrivacyChina;
    }
  }
  else {
    if (languageSelected) {
      contentPrivacy = StaticLabels[languageSelected].consentPrivacy;
    }
    else {
      contentPrivacy = StaticLabels["en"].consentPrivacy;
    }
  }
  const welcomPolicyclassName = `welcomePolicySection ${window.config.isChina ? 'welcomePolicySectionChina' : ''}`;

  const removeDLCookies = () => {
    eraseCookie(window.config.businessUnit + '-Token');
    eraseCookie(window.config.businessUnit + '-Conversation');
    eraseCookie(window.config.businessUnit + '-botMaximise');
    eraseCookie(window.config.businessUnit + '-AuthToken');
    eraseCookie(window.config.businessUnit + '-OldCoversation');
    eraseCookie(window.config.businessUnit + '-Language');
    eraseCookie(window.config.businessUnit + '-EnableSendBox');
    eraseCookie(window.config.businessUnit + '-BotLastResponseTime');
    eraseCookie(window.config.businessUnit + '-botMaximiseTime');
    eraseCookie(window.config.businessUnit + '-CheckOrderLob');
    eraseCookie(window.config.businessUnit + '-CheckOriginalId');
    eraseCookie(window.config.businessUnit + '-WebPopup');
  };
  const sendEvent = useSendEvent();
  
  var headerClass = classNames({
    headerMax: true,
    header: true
  });
  
function formatAMPM(date) {
  var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  return { hour: hours, ampm: ampm };
}

  const GreetUser=()=>{
    const timeFormat = formatAMPM(new Date());
    const language = languageSelected ?languageSelected:"en";
    if (timeFormat.ampm === 'AM') {
       return StaticLabels[language].GoodMorning;
    } else {
      if (timeFormat.hour >= 4 && timeFormat.hour <= 7) {
        return  StaticLabels[language].GoodEvening;
      }
      if (timeFormat.hour >= 1 && timeFormat.hour <= 4) {
        return  StaticLabels[language].GoodAfternoon;
      }
  
      if (timeFormat.hour === 12) {
        return  StaticLabels[language].GoodAfternoon;
      }
  
      if (timeFormat.hour > 7 && timeFormat.hour) {
        return  StaticLabels[language].GoodEvening;
      }
    }
  }
   const handleArticleModalClose=()=>{
      removeDLCookies();
      setIsPrivacyAccepted(true);
      setPrivacyAccepted(true);
      setCookie(window.config.businessUnit + '-isPrivacyAccepted', true);
      setDirectLineTokenCall();
  }
  return (
  <div className='nomarginpadding welcomePolicyDiv'>
    {!isPrivacyAccepted?
       <><div className={"webChat"}><ul className="welcomecardBotDivHeight"><li className="botDivHeight privacyList"><div style={{ padding: '0px', marginTop: '-13px' }}><div className={headerClass}>
       <div className="ask-ellie">
         <img
           className="launcherIconAvatar"
           alt="miniAvatar"
           src={HeaderLogo_HBT}
         />
         <h4 style={{ fontWeight: '600' }}>Honeywell Assist</h4>
       </div>
       <ul>
         <li style={{ cursor: 'pointer' }}>
           <img alt="Close" title="Close Bot" src={Close} onClick={handleModalChange({
             isOpenModal: false,
             value: null,
             target: "closeLaunchIcon"
           })}></img>
         </li>
       </ul>
     </div>
    <div className="homeContainer welcomePolicyContainer">
     <div className={welcomPolicyclassName}>
        {!isPrivacyAccepted && <>
          <div style={{ marginBottom: '1rem'}} className="botTextBox conversationTextBox conversationTextBoxWelcomeMessage">
                <div className="conv-text welcomeMessage">
                  <p>{languageSelected ? StaticLabels[languageSelected].greetUserPrivacy.replace("[GreetDCAUser]", `${GreetUser()}`):StaticLabels["en"].greetUserPrivacy.replace("[GreetDCAUser]", `${GreetUser()}`)}</p>
                </div>
              </div>
              <div style={{ marginBottom: '1rem'}} className="botTextBox conversationTextBox conversationTextBoxWelcomeMessage">
              <div className="conv-text welcomeMessage"><p>{<Markdown
          options={{
            overrides: {
              forceBlock: true,
              forceInline: false,
              a: {
                component: MyLink,
                target: "_blank",
                props: {
                  className: "markdown-link",
                  target: "_blank"
                }
              }
            }
          }}
        >{contentPrivacy}</Markdown>}</p></div></div>
        </>}
     </div>
    <div className="policyButton">
     <button className={"btn-secondary"} onClick={handleArticleModalClose}>{languageSelected ? StaticLabels[languageSelected].acceptText : StaticLabels["en"].acceptText}</button>
      <button className="btn-secondary" onClick={handleModalChange({
             isOpenModal: false,
             value: null,
             target: "closeLaunchIcon"
           })}>{languageSelected ? StaticLabels[languageSelected].declineText : StaticLabels["en"].declineText}</button>
    </div>
    </div></div></li></ul></div></>:<Spinner
    isMaximised={true}
    showSpinner={true}
    handleModalChange={handleModalChange}
  />
    }
  </div>
  )
}

export default PrivacyPolicy;